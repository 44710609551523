<template>
  <section class="m-account m-scroll" @scroll="handleScroll($event)">
    <m-header title="My Account" :badge="badge">
      <div slot="right" style="width:40px">
      </div>
    </m-header>
    <router-link to="/en/setting/user" class="left-bar-top">
        <img :src="user.pic_url == '' ? $store.state.avatar :  user.pic_url" alt="avatar">
        <div class="user-info">
        <h3>
            {{user.nickname}}
            <img src="@/assets/leftbar/icon_xingbie@2x.png" alt="">
        </h3>
        <p>{{user.phone == '' ? user.email : user.phone}}</p>
        </div>
    </router-link>
    <div class="bar-link-item" v-for="(item, index) in linkList" :key="index" @click="$router.push(item.path)">
        <span>{{item.name}}</span>
        <img src="@/assets/payment/icon-fanhui@2x.png" alt="">
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/en/tab-header.vue'
import {  getAccountInfo } from '@/api/zh/mine.js'
export default {
    name:'Account',
    components:{ MHeader },
    data(){
      return {
        linkList: [
            {name: 'Wishlist',             path: '/en/favorite'},
            {name: 'My Order',             path: '/en/order/0'},
            {name: 'Comment',              path: '/en/comment/list'},
            {name: 'Your Wallet',          path: '/en/wallet'},
            {name: 'Your Addresses',       path: '/en/address'},
            {name: 'Gift cards & Coupons', path: '/en/coupon'},
            // {name: 'Login & security',     path: '/en'},
            // {name: 'Switch Accounts',      path: '/en/change'},
        ],
        user: { pic_url: '', nickname: 'MoobyYoho', phone: '', email: '' },
        badge: ''
      }
    },
    methods: {
      
      //获取用户信息
      getAccount() {
        // 个人中心 信息
        getAccountInfo().then(res => {
          if(res) {
            this.user = res.data
            localStorage.setItem('setPersonal',JSON.stringify(res.data))
          }
        })
      },

    },

    created() {
      this.getAccount()
    }
}
</script>

<style lang="less" scoped>
@import './account.less';
</style>